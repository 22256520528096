/*
 * Navigation
 */

// Navigation fixe
const header = document.getElementById("header__navigation");

function scrolled() {
    header.className = (window.scrollY >= header.offsetHeight) ? "header__navigation header--fixed" : "header__navigation";
}
addEventListener("scroll", scrolled, false);

// Switch menu mobile/desktop
const btn = document.getElementById('header__navigationBtn');
btn.addEventListener('click', function() {
    // document.getElementById('navigationBar').classList.toggle('is-visible');
    document.getElementById('header__menu').classList.toggle('is-visible');
});

// Switch account mobile/desktop
// if (document.getElementById('header__connexion')) {
//     const connexion = document.getElementById('header__connexion');
//     connexion.addEventListener('click', function() {
//         document.getElementById('header__connexionBloc').classList.toggle('is-visible');
//     });
// }

// Mon compte
if (document.getElementById('header__accountBtn')) {
    const account = document.getElementById('header__accountBtn');
    account.addEventListener('click', function() {
        document.getElementById('header__accountBloc').classList.toggle('is-visible');
    });
}

// Sub menu
const headerBefore = document.getElementById('header');
const menuHoverBtn = document.querySelectorAll('.header__menuHoverBtn');
menuHoverBtn.forEach(function(item) {
    if (item.childNodes[2] != undefined) {
        //when a menu is hovered
        item.addEventListener('mouseenter', function(event) {
            //its sub menu is displayed, the sub menus of the other menus are not 
            let subMenu = document.querySelectorAll('.header__subMenu');
            subMenu.forEach(function(sm) {
                sm.classList.remove('header__subMenu--is-visible');
            });
            item.childNodes[2].classList.add('header__subMenu--is-visible');
            headerBefore.classList.add('header--is-visible');
        });
        //when the mouse leaves the header
        header.addEventListener('mouseleave', function(event) {
            //its sub menu is not displayed anymore 
            item.childNodes[2].classList.remove('header__subMenu--is-visible');
            headerBefore.classList.remove('header--is-visible');
        });
        let contactMenu = document.querySelector('#contactMenu');
        //when the contact menu is hovered  
        contactMenu.addEventListener('mouseenter', function(event) {
            //no sub menu is displayed
            item.childNodes[2].classList.remove('header__subMenu--is-visible');
            headerBefore.classList.remove('header--is-visible');
        });
    }
});