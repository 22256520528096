var filterBtn = document.getElementsByClassName('moreOption');
var filterBloc = document.getElementsByClassName('grid__filterResult');
console.log(filterBtn);
//try {
// filterBtn.forEach(function(node) {
// 		node.addEventListener('click', function() {
// //			filterBloc.classList.toggle('grid__filterResult--is-collapse', 'grid__filterResult--is-visible');
// 		});
// 	})
//} catch (e) {}
