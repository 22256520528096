import LazyLoad from "vanilla-lazyload/dist/lazyload.min.js";

$(document).ready(function(){
  console.log('Jquery Ready');
  var myLazyLoad = new LazyLoad({
    elements_selector: ".lazy"
  });

  nextStepRegister();
  stepSelectorRegister();
  expertAjaxCall(myLazyLoad);
  expendCustomFilter();
  enableReturnTop();
  enableFaqAccordeon();
  enableCommentTab();
});



var nextStepRegister = function(){
  $('.fieldset__next-step').on('click', function(e){
    e.preventDefault();
    var currentStep = $(this).closest('.fieldset__step').attr('data-step');
    if(checkStepInputs($('.fieldset__step[data-step="' + currentStep + '"]'))){
      $('.fieldset__step[data-step="' + currentStep + '"]').removeClass('active');
      currentStep = parseInt(currentStep) + 1;
      $('.fieldset__step[data-step="' + currentStep + '"]').addClass('active');
    }
  });
};

var stepSelectorRegister = function(){
  $('.fieldset__dots .dot.active').on('click', function(){
    var clickedStep = $(this).attr('data-step');
    $('.fieldset__step.active').removeClass('active');
    $('.fieldset__step[data-step="' + clickedStep + '"]').addClass('active');
  });
};


var checkStepInputs = function(container){
  var checkedInputs = true;
  $(container).find('input, select').filter('[required]').each(function(){
    if($(this).val() == ''){
      $(this).addClass('error');
      checkedInputs = false;
    }
  });
  return checkedInputs;
};



var expertAjaxCall = function(myLazyLoad){
  $('.more__link.authors-more').on('click', function(e){	
    $('#loader').fadeIn();
    var url = $(this).attr('href');
    var filter = $(this).attr('data-filter');
    var length = $(this).attr('data-authors-length');
    var container = $(this).closest('.grid').find('.grid__bloc');
    var that = $(this);
    var href = $(this).attr('data-path');
    var asset = $(this).attr('data-asset');
    var speciality = $(this).attr('data-speciality');
    var keywords = $(this).attr('data-keywords');
    $.ajax({
      method: 'get',
      url: url,
      contentType: 'application/json; charset=utf-8',
      data: 'filter=' + filter + '&length=' + length + '&speciality=' + speciality + '&keywords=' + keywords
    }).done(function(response){
      $('#loader').fadeOut();
      response = JSON.parse(response);
      if(response.length % 10 != 0 || response.length == 10){
        $(that).fadeOut();
      }else{
        $(that).fadeIn();
      }
      $(that).attr('data-authors-length', parseInt($(that).attr('data-authors-length')) + 10);
      $(container).html('');
      for(var i = 0; i<response.length; i++){
        insertAjaxExpertInTemplate(container, response[i], href, asset);
        myLazyLoad.update();
      }
	  
    }).fail(function(jxh, textmsg, errorThrown){
      $('#loader').fadeOut();
      console.log(jxh);
      console.log(textmsg);
      console.log(errorThrown);
    });
    e.preventDefault();
    return false;
  });
};
///images/speakersjames-couvreur_20190211112212.jpeg
var insertAjaxExpertInTemplate = function(container, elem, href, asset){
  // console.log(elem);
  href = href.replace('fakenom', elem.fullName);
  var item = '<div class="grid__item">' +
             '<a href="' + href + '" class="grid__itemExpert"><span class="grid__itemExpertThumb">';
  if(elem.img != null){
    // console.log('has img');
    item = item + '<img class="lazy" data-src="' + asset + 'images/speakers/' + elem.img + '" width="150" alt=""/>';
  }else{
    // console.log('no img');
    item = item + '<img class="lazy" data-src="' + asset + 'images/silhouette.svg" width="150" alt=""/>';
  }
  item = item + '</span><p class="grid__itemExpertTitle">';
  if(elem.nom == null || elem.prenom == null){
    item = item + '<span class="grid__itemExpertFullname">' + elem.fullName + '</span><br/><span class="grid__itemExpertSpe">' + elem.speciality + '</span></p>';
  }else{
    item = item + '<span class="grid__itemExpertLastname">' + elem.nom + '</span> <span class="grid__itemExpertFirstname">' + elem.prenom + '</span><br/><span class="grid__itemExpertSpe">' + elem.speciality + '</span></p>';
  }
  item = item + '</a></div>';

  $(container).append(item);
};

var expendCustomFilter = function() {
	$('.filters__btn').on('click', function() {
    $(this).toggleClass('is-visible');
		if($(this).next('.filters__choice').hasClass('filters__choice--is-visible')) {
			$(this).next('.filters__choice').removeClass('filters__choice--is-visible');
			$('.filters__containerBlock').removeClass('o80');
		} else {
			$('.filters__choice').removeClass('filters__choice--is-visible');
			$(this).next('.filters__choice').toggleClass('filters__choice--is-visible');
			$('.filters__containerBlock').addClass('o80');
			$(this).parent('.filters__containerBlock').removeClass('o80');
		}
	});
};

var enableCommentTab = function(){
  $('.anchors .anchor').on('click', function(){

    $('.anchors .anchor').removeClass('active');
    $(this).addClass('active');
    $('.comment-container').removeClass('opened');
    if($(this).hasClass('form-anchor')){
      $('#comment-form').addClass('opened');
    }else if($(this).hasClass('comments-anchor')){
      $('#comments-viewer').addClass('opened');
    }
  });
};

var enableReturnTop = function(){
  $('.return-top').on('click', function(){
    console.log('clicked');
    $('html, body').animate({
      scrollTop: 0
    }, 1000);
  });
};

var enableFaqAccordeon = function(){
  $('.faq-container li').on('click', function(){
    $(this).toggleClass('visible');
  });
};